.form {
  grid-area: form;
  background-color: #08071c;
  padding: 20px 22px;
}

.topMargin-2 {
  margin-top: 100px;
}

.info {
  grid-area: info;
  background-color: #08071c;
  padding: 20px 22px;
}

.map {
  grid-area: map;
  background-color: #38d16a;
}

.contact-box {
  position: relative;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 5fr 4fr;
  grid-template-areas: "form info" "form map";
  grid-gap: 20px;
}

.formBox {
  position: relative;
  width: 100%;
}

.formBox .row50 {
  display: flex;
  gap: 20px;
}

.inputBox {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 50%;
}

.formBox .row100 .inputBox {
  width: 100%;
}

.inputBox span {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: 800;
}

.inputBox input {
  padding: 10px;
  font-size: 1.1em;
  outline: none;
  border: 1px solid #333;
}

.inputBox textarea {
  padding: 10px;
  font-size: 1.1em;
  outline: none;
  border: 1px solid #333;
  resize: none;
  min-height: 150px;
  margin-bottom: 10px;
}

.inputBox input[type="submit"] {
  background: #fff;
  outline: none;
  border: none;
  color: #08071c;
  font-weight: 700;
  font-size: 1.1em;
  transition: 0.5s;
  cursor: pointer;
  max-width: 120px;
  padding: 14px 15px;
}

.inputBox input[type="submit"]:hover {
  background-color: #38d16a;
  color: #fff;
}

.inputBox::placeholder {
  color: #999;
}

/* infoBox */

.info .infoBox div {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.info .infoBox div span {
  min-width: 40px;
  height: 40px;
  color: #fff;
  background-color: #08071c;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  border-radius: 50%;
  margin-right: 15px;
}

.infoBox p {
  color: #fff;
  font-weight: 800;
  font-size: 1.1em;
}

.info .infoBox div a {
  color: #fff;
  text-decoration: none;
}

.sci {
  margin-top: 40px;
  display: flex;
}

.sci li {
  list-style: none;
  margin-right: 15px;
}

.sci li a {
  color: #fff;
  font-size: 1.5em;
}

.sci li a:hover {
  color: #38d16a;
}
/* infoBox */

.map {
  padding: 0;
}

.map iframe {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 991px) {
  .contact-box {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: "form" "info" "map";
  }

  .formBox .row50 {
    display: flex;
    gap: 0;
    flex-direction: column;
  }

  .inputBox {
    width: 100%;
  }

  .map {
    min-height: 300px;
  }
}
