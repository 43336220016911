.services {
  background-color: #f8f9fe;
  padding: 50px 0;
  min-height: 90vh;
}

.services .box {
  text-align: left;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

.services img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: contain;
}

.services .text {
  background-color: #fff;
  box-shadow: 0px 5px 20px 0px rgb(0 0 0 /60%);
  padding: 30px;
  margin: 20px;
  margin-top: -30px;
  position: relative;
  z-index: 1;
  border-radius: 10px;
}

.services .text h2 {
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 20px;
}
